import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useAccountDetails, useBillComparison, useExperian } from 'api';
import { useUserState } from 'auth/authenticate';
import { ReconnectTitle, ViewCurrentBillButton } from 'component-library';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { routes } from 'constants/';
import { useRestoreDetails } from 'hooks';
import { logger } from 'services';

import onViewCurrentBillClick from './onViewCurrentBillClick';
import ReconnectAccountInformation from './ReconnectAccountInfo';
import ReconnectSERForm from './ReconnectSERForm';
import ReconnectServiceForm from './ReconnectServiceForm';
import ServiceSelection from './ServiceSelection';

const ReconnectServiceContent: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();

  const { isAccountDetailsSuccess, accountDetailsResponse } = useAccountDetails({
    enabled: isAuthenticated,
    onError: (error: unknown): void => {
      logger.info('[Reconnect Service Page] Account Details API Call Error!', error);

      navigate(routes.error.reconnect);
    },
  });

  const { isRestoreDetailsSuccess, restoreDetails } = useRestoreDetails();

  const { isExperianSuccess, experianResponse, mutateExperian } = useExperian({
    onSuccess: (response: AxiosResponse<ExperianResponse>) => {
      logger.info('[Reconnect Service Page] Experian API Call Success!', response);

      if (restoreDetails) {
        const {
          depositAmount: depositAmountString,
          eligibilityFlag,
          reconnectionFee: reconnectionFeeString,
          totalPastDueAmount: totalPastDueAmountString,
        } = restoreDetails;
        const depositAmount = parseFloat(depositAmountString);
        const reconnectionFee = parseFloat(reconnectionFeeString);
        const totalPastDueAmount = parseFloat(totalPastDueAmountString);

        if (response.data.bpemCategory === 'R887') {
          logger.info('[Reconnect Service Page] - Experian API Call Success! R887 Probate Error');

          navigate(routes.reconnect.probate);
        }

        // Interrupted Flow - now triggers AFTER experian is successful
        // TODO - Bug #52155 - restore code below once the eligibility service is fixed i.e. return correct value for reconnection fee
        if (eligibilityFlag && totalPastDueAmount + depositAmount === 0) {
          // + reconnectionFee
          navigate(routes.reconnect.confirmation, { state: { previousPath: 'reconnect-payment-options' } });
        }

        const webUserId = localStorage.getItem('webUserId');

        localStorage.setItem(`depositAmount_${webUserId}`, depositAmount.toFixed(2));
        localStorage.setItem(`reconnectionFee_${webUserId}`, reconnectionFee.toFixed(2));
        localStorage.setItem(`totalAmountPastDue_${webUserId}`, totalPastDueAmount.toFixed(2));
      } else {
        navigate(routes.error.reconnect);
      }
    },
    onError: (error: unknown) => {
      logger.info('[Reconnect Service Page] Experian API Call Error!', error);

      navigate(routes.error.reconnect);
    },
  });

  useEffect(() => {
    if (isRestoreDetailsSuccess && restoreDetails) {
      const experianRequest = {
        businessPartner: 123,
        premiseId: `${restoreDetails.premise}`,
        processId: 'WRE',
        webUserId: localStorage.getItem('webUserId') ?? '',
      };

      setTimeout(() => {
        mutateExperian(experianRequest);
      }, 500);
    }
  }, [isRestoreDetailsSuccess, restoreDetails]);

  const { contractAccountNo, depositAmount, eligibilityFlag, premise, totalPastDueAmount } = restoreDetails;
  const accountNumber = contractAccountNo ? contractAccountNo.toString() : '';
  const notZero =
    !!totalPastDueAmount && !!depositAmount && parseFloat(totalPastDueAmount) + parseFloat(depositAmount) !== 0;
  const enableBillComparisonQuery = isAuthenticated && !!premise && !!accountNumber && !!eligibilityFlag && notZero;

  const { isBillComparisonSuccess, billComparisonResponse } = useBillComparison({
    accountNumber,
    enabled: enableBillComparisonQuery,

    onError: (error: unknown) => {
      logger.info('[Reconnect Service Page] Bill Comparison API Call Error!', error);

      navigate(routes.error.reconnect);
    },
    premise,
    queryKey: ['bill-comparison', premise!, accountNumber!],
  });

  const showRenderPageContent =
    isAccountDetailsSuccess &&
    isRestoreDetailsSuccess &&
    restoreDetails &&
    isBillComparisonSuccess &&
    isExperianSuccess;

  if (showRenderPageContent) {
    return (
      <>
        <ViewCurrentBillButton onClick={onViewCurrentBillClick} />
        <ReconnectTitle />
        <ReconnectAccountInformation
          accountDetailsResponse={accountDetailsResponse?.data}
          accountNumber={restoreDetails?.contractAccountNo!}
        />
        <ServiceSelection
          mpscComponent={
            <ReconnectServiceForm
              billComparisonResponse={billComparisonResponse}
              experianResponse={experianResponse?.data!}
              restoreDetails={restoreDetails}
            />
          }
          serComponent={
            <ReconnectSERForm
              billComparisonResponse={billComparisonResponse}
              experianResponse={experianResponse?.data!}
              restoreDetails={restoreDetails}
            />
          }
        />
      </>
    );
  }
  return <LoadingSpinner />;
};

export default ReconnectServiceContent;
