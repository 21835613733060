/* eslint-disable indent */
import React, { FC } from 'react';
import { List, ListItem, SxProps, Typography, useTheme } from '@mui/material';

import getFlagsConfig from 'config/getFlagsConfig';

import { ReadOnlyCurrencyMask } from '../../../component-library/currency/ReadOnlyCurrencyMask';
import { useResponsiveSx } from '../../../theme/breakpoints/responsiveHooks';
import { PaymentInfo } from '../PaymentOptionsModels';

import messages from './messages';

type Props = {
  paymentInfo: PaymentInfo;
  isMinimumAmountDue: boolean;
};

const ListAmountDue: FC<Props> = (props: Props): JSX.Element => {
  const { paymentInfo, isMinimumAmountDue } = props;
  const theme = useTheme();
  const { enableMPSC } = getFlagsConfig();
  const sx: Record<string, SxProps> = {
    listWrapper: {
      padding: '0px',
    },
    listItemWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: theme.palette.secondary.main,
      ...theme.typography.body1,
    },
    listItemWrapperMobile: {
      padding: '8px 24px',
    },
    listItemWrapperTabletAndDesktop: {
      padding: '8px 12px',
    },
    divider: {
      borderBottom: '1px solid #D9D9D9',
    },
    dollarAmount: {
      paddingLeft: '36px',
    },
  };

  const { pastDueAmount, reconnectAmount, securityDepositAmount, currentBillAmount, minimumAmountDue, totalAmountDue } =
    paymentInfo;
  const calculatedAmountDue: number = isMinimumAmountDue ? minimumAmountDue : totalAmountDue;

  const mobileListItemWrapper = {
    ...sx.listItemWrapper,
    ...sx.listItemWrapperMobile,
  };
  const tabletAndDesktopListItemWrapper = {
    ...sx.listItemWrapper,
    ...sx.listItemWrapperTabletAndDesktop,
  };
  const responsiveListItemSx = useResponsiveSx(
    mobileListItemWrapper,
    tabletAndDesktopListItemWrapper,
    tabletAndDesktopListItemWrapper,
  );

  const isMinimumAmountDueStyling = enableMPSC
    ? {
        ...responsiveListItemSx,
        background: theme.palette.dteRed.main,
        color: theme.palette.primary.main,
        padding: '21.5px 12px',
      }
    : { ...responsiveListItemSx, background: theme.palette.dteRed.main, color: theme.palette.primary.main };

  const listItemWrapperWithDivider = { ...responsiveListItemSx, ...sx.divider };
  const totalAmountStyling = isMinimumAmountDue
    ? isMinimumAmountDueStyling
    : { ...responsiveListItemSx, background: theme.palette.dteGrey.light, color: theme.palette.primary.main };

  const minAmountDueText = enableMPSC ? messages.minAmountDueText : 'Minimum Amount Due to Reconnect Service';

  return (
    <List data-testid="list-amount-due-component" sx={sx.listWrapper}>
      <ListItem data-testid="sub-total-items-styling" sx={listItemWrapperWithDivider}>
        <Typography>{enableMPSC ? messages.balancePriorToCurrentBill : `Past-due Balance`}</Typography>
        <Typography data-testid="past-due-balance-list-amount" sx={sx.dollarAmount}>
          {ReadOnlyCurrencyMask(pastDueAmount)}
        </Typography>
      </ListItem>
      {enableMPSC ? (
        <>
          <ListItem sx={listItemWrapperWithDivider}>
            <Typography>{messages.securityDepositText}</Typography>
            <Typography data-testid="security-deposit-list-amount" sx={sx.dollarAmount}>
              {ReadOnlyCurrencyMask(securityDepositAmount)}
            </Typography>
          </ListItem>
          <ListItem sx={listItemWrapperWithDivider}>
            <Typography>{messages.reconnectionFeeText}</Typography>
            <Typography data-testid="reconnection-fee-list-amount" sx={sx.dollarAmount}>
              {ReadOnlyCurrencyMask(reconnectAmount)}
            </Typography>
          </ListItem>
        </>
      ) : (
        <>
          <ListItem sx={listItemWrapperWithDivider}>
            <Typography>Reconnection Fee</Typography>
            <Typography data-testid="reconnection-fee-list-amount" sx={sx.dollarAmount}>
              {ReadOnlyCurrencyMask(reconnectAmount)}
            </Typography>
          </ListItem>
          <ListItem sx={listItemWrapperWithDivider}>
            <Typography>Security Deposit*</Typography>
            <Typography data-testid="security-deposit-list-amount" sx={sx.dollarAmount}>
              {ReadOnlyCurrencyMask(securityDepositAmount)}
            </Typography>
          </ListItem>
        </>
      )}
      {!isMinimumAmountDue && (
        <ListItem sx={listItemWrapperWithDivider}>
          <Typography data-testid="current-bill-label">Current Bill</Typography>
          <Typography data-testid="current-bill-list-amount" sx={sx.dollarAmount}>
            {ReadOnlyCurrencyMask(currentBillAmount)}
          </Typography>
        </ListItem>
      )}
      <ListItem data-testid="calculated-total-styling" sx={totalAmountStyling}>
        <Typography data-testid="calculated-total-label" variant="body2" fontWeight={600}>
          {isMinimumAmountDue ? minAmountDueText : 'Total Amount Due'}
        </Typography>
        <Typography sx={sx.dollarAmount} data-testid="calculated-total-list-amount" variant="body2" fontWeight={600}>
          {ReadOnlyCurrencyMask(calculatedAmountDue)}
        </Typography>
      </ListItem>
    </List>
  );
};

export default ListAmountDue;
