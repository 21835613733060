import { urls } from 'constants/urls';
import googleTagManager from 'services/googleTagManager';

const onViewCurrentBillClick = (): void => {
  googleTagManager.trackButtonClick({
    actionObject: 'view current bill',
    actionDetail: 'reconnect details page',
    authenticationStatus: true,
    transactionStepName: 'reconnect details',
    transactionStepNumber: '1',
  });
  window.location.href = urls.currentBillUrl;
};

export default onViewCurrentBillClick;
