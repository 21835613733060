const messages = {
  accordionHideDetailsLabel: 'Hide Details',
  accordionViewDetailsLabel: 'View Details',
  amountDueText: 'Amount Due',
  balancePriorToCurrentBillText: 'Balance Prior to Current Bill',
  minAmountDueText: 'Minimum Amount Due',
  otherChargesText: 'Other Charges',
  reconnectionFeeText: 'Reconnection Fee',
  securityDepositText: 'Security Deposit*',
};

export default messages;
