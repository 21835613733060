import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import PageTitles from 'constants/pageTitles';

import { HeaderTitle, reconnectService } from '../component-library/header/header';
import HeaderDesktop from '../component-library/header/HeaderDesktop';
import HeaderMobile from '../component-library/header/HeaderMobile';
import { PageTemplate } from '../component-library/page-templates/PageTemplate';
import getFlagsConfig from '../config/getFlagsConfig';
import routes from '../constants/routes';
import { useResponsiveRendering } from '../theme/breakpoints/responsiveHooks';

import PageNotFoundContent from './components/PageNotFoundContent';
import getPageHeaderTitle from './getPageHeaderTitle';

const PageNotFound = (): React.ReactElement => {
  const { enablePaymentAgreement } = getFlagsConfig();
  const location = enablePaymentAgreement ? useLocation() : { pathname: '' };

  const urlPaths = enablePaymentAgreement ? location?.pathname?.split('/') : ['/'];
  const headerTitle: HeaderTitle = enablePaymentAgreement ? getPageHeaderTitle(urlPaths) : reconnectService;

  const mobileHeader = <HeaderMobile titleText={headerTitle} isHeaderButtonRendered={false} />;
  const desktopHeader = <HeaderDesktop titleText={headerTitle} isHeaderButtonRendered={false} />;
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  if (enablePaymentAgreement) {
    window.history.replaceState(null, 'Page Not Found | DTE Energy', routes.pageNotFound);
  }

  return (
    <>
      <Helmet>
        <title>{PageTitles.PAGE_NOT_FOUND}</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="470px"
        dataTestId="not-found-error-container"
      >
        <PageNotFoundContent headerTitle={headerTitle} />
      </PageTemplate>
    </>
  );
};

export default PageNotFound;
