import React from 'react';

import ReconnectMPSCFormContent from './ReconnectMPSCFormContent';

type ReconnectSERFormProps = IReconnectServiceFormProps;

const ReconnectSERForm: React.FC<ReconnectSERFormProps> = (props: ReconnectSERFormProps): React.ReactElement => {
  const { billComparisonResponse, experianResponse, restoreDetails } = props;

  return (
    <ReconnectMPSCFormContent
      billComparisonResponse={billComparisonResponse?.data!}
      experianResponse={experianResponse}
      restoreDetails={restoreDetails}
    />
  );
};

export default ReconnectSERForm;
