import { styled, Theme, Typography } from '@mui/material';

import ReconnectComponent from './ReconnectComponent';
import { ReconnectNoticeTitle } from './ReconnectNoticeTitle';

const Container = styled(Typography)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.light,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '20px 30px',
}));

const ReconnectNotice: React.FC = (): React.ReactElement => (
  <Container data-testid="reconnect-notice">
    <ReconnectNoticeTitle />
    <ReconnectComponent />
  </Container>
);

export default ReconnectNotice;
