import { Box, SxProps } from '@mui/material';

import { DisclaimerNotice } from './DisclaimerNotice';
import OtherChargesNotice from './OtherChargesNotice';

const ReconnectComponent: React.FC = (): React.ReactElement => {
  const sx: Record<string, SxProps> = {
    noticesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
  };

  return (
    <Box data-testid="reconnect-notice-content" sx={sx.noticesWrapper}>
      <DisclaimerNotice />
      <OtherChargesNotice />
    </Box>
  );
};

export default ReconnectComponent;
