import { useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FormControlLabel, Radio, SxProps, useTheme } from '@mui/material';

import RadioDefaultIcon from 'component-library/icons/RadioDefaultIcon';
import RadioErrorIcon from 'component-library/icons/RadioErrorIcon';
import RadioSelectedIcon from 'component-library/icons/RadioSelectedIcon';
import { useBreakpoints } from 'theme/breakpoints/useBreakpoints';

import isInputTenTimesMinimumAmountDue from '../../paymentOptions/isInputTenTimesMinimumAmountDue';
import ListAmountDue from '../../paymentOptions/ListAmountDue';
import PayAnotherAmtDescription from '../../paymentOptions/PayAnotherAmtDescription';
import PaymentOptionsAccordion from '../../paymentOptions/PaymentOptionsAccordion';
import RadioLabelAdapter from '../../paymentOptions/RadioLabelAdapterV2';
import RadioLabelMinAmount from '../../paymentOptions/RadioLabelMinAmountV2';
import RadioLabelWithInput from '../../paymentOptions/RadioLabelWithInput';
import WarningTenTimesOver from '../../paymentOptions/WarningTenTimesOver';
import { PaymentOptionsContext } from '../../PaymentOptionsContext';
import { PaymentOption } from '../../PaymentOptionsModels';
import { PaymentOptionLabel } from '../radio-options/label';
import PastDueAndReconnectPaymentBreakdown from '../radio-options/payment-breakdown/PastDuePaymentBreakdown';

import messages from './messages';

interface IOptionSelectMobileProps {
  paymentInfo: IMpscPaymentInfo;
}

const OptionSelectMobile: React.FC<IOptionSelectMobileProps> = (props: IOptionSelectMobileProps): JSX.Element => {
  const { paymentInfo } = props;
  const { inputValue } = useContext(PaymentOptionsContext);
  const { isSmallMobile } = useBreakpoints();
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
    optionSelectMobileWrapper: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.dteGrey.dark}`,
    },
    failOptionSelectMobileWrapper: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.dteRed.dark}`,
    },
    formControlLabelSmallMobile: {
      margin: '0px',
      padding: '20px 7px 20px 0px',
    },
    formControlLabel: {
      margin: '0px',
      padding: '20px 30px 20px 0px',
    },
    radioButton: {
      marginLeft: '10px',
      padding: '14px',
    },
    radioLabelWithInputWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const { depositAmount, minAmountToReconnect, otherCharges, reconnectionFee, totalAmount, totalPastDueAmount } =
    paymentInfo;

  const oldPaymentInfo: IPaymentInfo = {
    currentBillAmount: 0,
    pastDueAmount: totalPastDueAmount,
    reconnectAmount: reconnectionFee,
    securityDepositAmount: depositAmount,
    minimumAmountDue: minAmountToReconnect,
    totalAmountDue: totalAmount,
  };

  const minAmountDueLabel = (
    <RadioLabelAdapter
      label={<RadioLabelMinAmount />}
      hasOtherCharges={!!otherCharges}
      calculatedTotal={minAmountToReconnect}
    />
  );
  const pastDueLabel = (
    <PaymentOptionLabel
      labelAmount={totalPastDueAmount}
      labelSubject={messages.pastDueSubjectLabel}
      labelDisclaimer={messages.pastDueDisclaimerLabel}
    />
  );

  const { formState } = useFormContext();
  const validationsSx =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected'
      ? sx.optionSelectMobileWrapper
      : sx.failOptionSelectMobileWrapper;

  const dteRadioIcon =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected' ? <RadioDefaultIcon /> : <RadioErrorIcon />;

  const radio2Ref = useRef<HTMLInputElement>(null);

  const handleTabIndexOnChange = (): void => {
    if (radio2Ref && radio2Ref.current) {
      radio2Ref.current.tabIndex = 0;
    }
  };

  return (
    <Box sx={sx.rootWrapper} data-testid="option-selection-mobile-container">
      <Box sx={validationsSx} data-testid="option-past-due-amount-container">
        <FormControlLabel
          data-testid="past-due-amount-radio"
          value={PaymentOption.PAST_DUE_AMOUNT}
          control={<Radio checkedIcon={<RadioSelectedIcon />} icon={dteRadioIcon} sx={sx.radioButton} />}
          label={pastDueLabel}
          sx={isSmallMobile ? sx.formControlLabelSmallMobile : sx.formControlLabel}
        />
        <Box sx={sx.listAndDescriptionWrapper}>
          <PaymentOptionsAccordion
            ariaIdLabel="min-amount-due"
            accordionContent={<PastDueAndReconnectPaymentBreakdown {...paymentInfo} />}
          />
        </Box>
      </Box>
      <Box sx={validationsSx} data-testid="option-min-amt-due-container">
        <FormControlLabel
          data-testid="min-amount-due-radio"
          value={PaymentOption.MINIMUM_AMOUNT_DUE}
          control={<Radio checkedIcon={<RadioSelectedIcon />} icon={dteRadioIcon} sx={sx.radioButton} />}
          label={minAmountDueLabel}
          sx={isSmallMobile ? sx.formControlLabelSmallMobile : sx.formControlLabel}
        />
        <PaymentOptionsAccordion
          ariaIdLabel="min-amount-due"
          accordionContent={<ListAmountDue paymentInfo={oldPaymentInfo} isMinimumAmountDue />}
        />
      </Box>
      <Box sx={validationsSx} data-testid="option-pay-another-amt-container">
        <FormControlLabel
          data-testid="pay-another-amount-radio"
          value={PaymentOption.PAY_ANOTHER_AMOUNT}
          control={
            <Radio
              checkedIcon={<RadioSelectedIcon />}
              icon={dteRadioIcon}
              inputRef={radio2Ref}
              onChange={handleTabIndexOnChange}
              sx={sx.radioButton}
              tabIndex={-1}
            />
          }
          label={<RadioLabelWithInput labelOnChange={handleTabIndexOnChange} />}
          sx={sx.formControlLabel}
        />
        {isInputTenTimesMinimumAmountDue(inputValue, minAmountToReconnect) ? <WarningTenTimesOver /> : <></>}
        <PayAnotherAmtDescription minAmountDue={minAmountToReconnect} />
      </Box>
    </Box>
  );
};

export default OptionSelectMobile;
