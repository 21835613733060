import * as React from 'react';
import { styled, Theme, Typography } from '@mui/material';

import { DteButton } from 'component-library/buttons/DteButton';
import { GatherDocumentsIcon } from 'component-library/icons/GatherDocumentsIcon';
import { UploadDocumentIcon } from 'component-library/icons/UploadDocumentIcon';
import { WeWillContactYouIcon } from 'component-library/icons/WeWillContactYouIcon';
import { Step } from 'component-library/step/Step';
import { Bold } from 'component-library/typography/Bold';
import { urls } from 'constants/urls';

import { submitDocumentsOnClickHandler } from '../handlers';
import messages from '../messages';

type RequiredDocumentsProps = {
  lockEndDate?: string;
};
export const RequiredDocumentsV2 = ({ lockEndDate }: RequiredDocumentsProps): React.ReactElement => {
  const RequiredDocumentsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const Heading = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: '400',
    lineHeight: '38px',
    paddingBottom: '12px',
  }));

  const Instructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '18px',
    paddingBottom: '40px',
    [theme.breakpoints.between('tablet', 'desktop')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'center',
    },
  }));

  const Steps = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    gap: '24px',
    paddingBottom: '40px',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      paddingBottom: '32px',
    },
  }));

  return (
    <RequiredDocumentsContainer data-testid="required-documents-container">
      <Heading>{messages.heading}</Heading>
      <Instructions>
        {messages.instruction} <Bold>before {lockEndDate}</Bold> {messages.instructionAfterDate}
      </Instructions>
      <Steps>
        <Step
          title={messages.stepOne}
          subTitle={messages.stepOneSubTitle}
          icon={<GatherDocumentsIcon />}
          instructions={messages.stepOneInstruction}
        />
        <Step
          title={messages.stepTwo}
          subTitle={messages.stepTwoSubTitle}
          icon={<UploadDocumentIcon />}
          instructions={messages.stepTwoInstruction}
        />
        <Step
          title={messages.stepThree}
          subTitle={messages.stepThreeSubTitle}
          icon={<WeWillContactYouIcon />}
          instructions={messages.stepThreeInstruction}
        />
      </Steps>
      <DteButton
        variant="primary"
        text="Submit Documents"
        href={urls.documentSubmissionUrl}
        onClick={submitDocumentsOnClickHandler}
      />
    </RequiredDocumentsContainer>
  );
};
