import React from 'react';
import { PaymentOptionsProvider } from 'reconnect-service/components';
import { PaymentOptionsForm } from 'reconnect-service/components/form';
import MpscInformation from 'reconnect-service/components/form/option-select/MpscInformation';
import ReconnectNotice from 'reconnect-service/components/ReconnectNotice';

import { DialogProvider } from 'component-library/dialog/DialogContext';
import { mockBaseSuccessRestoreDetails } from 'testing/mocks/data';

type ReconnectMPSCFormProps = IReconnectServiceFormProps;

const ReconnectMPSCForm: React.FC<ReconnectMPSCFormProps> = (props: ReconnectMPSCFormProps): React.ReactElement => {
  const { experianResponse, restoreDetails } = props;

  /* TODO: remove two lines below and use restoreDetails from props above once BFF is deployed */
  const shouldUseMockData = true;
  const restoreDetailsResult = shouldUseMockData ? mockBaseSuccessRestoreDetails.restoreDetails : restoreDetails;

  return (
    <>
      <MpscInformation />
      <PaymentOptionsProvider>
        <DialogProvider>
          <PaymentOptionsForm experianResponse={experianResponse} restoreDetails={restoreDetailsResult} />
        </DialogProvider>
      </PaymentOptionsProvider>
      <ReconnectNotice />
    </>
  );
};

export default ReconnectMPSCForm;
