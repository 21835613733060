import React from 'react';

import getFlagsConfig from 'config/getFlagsConfig';

interface IServiceSelectionProps {
  mpscComponent: React.ReactNode;
  serComponent: React.ReactNode;
}

const ServiceSelection: React.FC<IServiceSelectionProps> = (props: IServiceSelectionProps): React.ReactElement => {
  const { mpscComponent, serComponent } = props;
  const { enableSER } = getFlagsConfig();
  const isSEREligible = false; // change to false when pushing to QA or for local dev if MPSC. TODO: Add SER eligibility check from API

  if (enableSER && isSEREligible) {
    return <>{serComponent}</>;
  }

  return <>{mpscComponent}</>;
};

export default ServiceSelection;
