import { styled, Theme, Typography } from '@mui/material';

export const StyledLabelContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.between('mobile', 'tablet')]: {
    width: '100%',
  },
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  paddingLeft: '22px',
  paddingRight: '36px',
  color: theme.palette.primary.main,
}));

export const StyledSubjectText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: '18px',
  wordBreak: 'break-word',
  whiteSpace: 'normal',
}));
