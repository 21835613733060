import React from 'react';
import { styled, Theme, Typography } from '@mui/material';

import { ReadOnlyCurrencyMask } from 'component-library';

import { StyledLabelContainer, StyledSubjectText } from './StyledLabelComponents';

type PaymentOptionLabelProps = IPaymentOptionTextLabel;

const PaymentOptionLabel: React.FC<PaymentOptionLabelProps> = (props: PaymentOptionLabelProps): React.ReactElement => {
  const { labelAmount, labelDisclaimer, labelSubject } = props;

  const formattedLabemAmount = ReadOnlyCurrencyMask(labelAmount);

  const AmountText = styled(Typography)(({ theme }: { theme: Theme }) => ({
    ...theme.typography.title,
    color: theme.palette.primary.main,
    fontSize: '32px',
  }));

  const DisclaimerText = styled(Typography)(({ theme }: { theme: Theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.secondary.main,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16.34px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  }));

  return (
    <StyledLabelContainer>
      <StyledSubjectText>{labelSubject}</StyledSubjectText>
      <AmountText>{formattedLabemAmount}</AmountText>
      <DisclaimerText>{labelDisclaimer}</DisclaimerText>
    </StyledLabelContainer>
  );
};

export default PaymentOptionLabel;
